import { call, put, select, delay } from 'redux-saga/effects';
import { actions as modalActions } from "@/redux/slices/modalSlice";
let history = null;
let anchorModule = null;
let addModalModule = null;
let startedModalListener = false;


export function* initAnchorModalListener() {
  if (!anchorModule) {
    anchorModule = yield call(() => import('./anchor-modal-listener-module'));
  }
  yield call(anchorModule.initAnchorModalListenerModule, { end: false, history });
}

export function* omitAnchorListernerOneTime(flag){
  if (!anchorModule) {
    anchorModule = yield call(() => import('./anchor-modal-listener-module'));
  }
  yield call(anchorModule.setOmitAnchorListernerOneTime, flag);
}


export function* addModal({payload}) {
  const data = payload;
  if(!history) {
    history = yield call(() => import('history'));
    history = history.createBrowserHistory();
    if (!startedModalListener) {
      startedModalListener = true;
      yield put(modalActions.initAnchorModalListener());
    }
  }
  if (data && (data.omitAnchorListener)) {
    yield call(omitAnchorListernerOneTime, true);
  } else {
    yield call(omitAnchorListernerOneTime, false);
  }
  // hacemos el import 
  if (!addModalModule){
    addModalModule = yield call (() => import ('./add-modal'));
  }
  yield call(addModalModule.default, { ...data, history});
}


