import { call, all, select } from 'redux-saga/effects';

let initSocketFlag = null;

export function* initSocket({payload}) {
  if(!initSocketFlag){
    ({ initSocket } = yield call(() => import('./init-socket')))
  }
  yield call(initSocket, { ...payload })
}

let send2TicketFlag = null;

export function* send2Ticket({payload}) {
  if(!send2TicketFlag){
    ({ send2Ticket } = yield call(() => import('./send-2-ticket')))
  }
  yield call(send2Ticket, payload)
}