import { call, all, select } from 'redux-saga/effects';

let launchNotification = null;

export function* launchNotificationFunction({payload}) {
  if(!launchNotification){
    ({ launchNotification } = yield call(() => import('./launch-notification')))
  }
  yield call(launchNotification, { data: payload })
}

let changeNotification = null;

export function* changeNotificationFunction({payload}) {
  if(!changeNotification){
    ({ changeNotification } = yield call(() => import('./change-notification')))
  }
  yield call(changeNotification, { data: payload })
}