import { createSlice } from '@reduxjs/toolkit'
import { getCookie, setCookie } from '@/utils/cookies'
// Define the initial state using that type
const initialState = {
  businessId: '',
  tableId: -1,
  tableKey: ''
}

const COOKIE_NAME = 'customer-session'
const COOKIES_OPTIONS =  {
  path: '/customer'
}

export const customerSessionSlice = createSlice({
  name: 'customerSession',
  initialState,
  reducers: {
    setSessionInfo: (state, { payload }) => {
      if (payload.tableId) {
        state.tableId = payload.tableId;
        setCookie(null, null, `${COOKIE_NAME}.tableId`, payload.tableId, COOKIES_OPTIONS)
      }
      if (payload.businessId) {
        state.businessId = payload.businessId;
        setCookie(null, null, `${COOKIE_NAME}.businessId`, payload.businessId, COOKIES_OPTIONS)
      }
      

    },
    initializeSession: (state, action) => { 
      const businessId = getCookie(null, null, `${COOKIE_NAME}.businessId`);
      const tableId = getCookie(null, null, `${COOKIE_NAME}.tableId`);
      const tableKey = getCookie(null, null, `${COOKIE_NAME}.tableKey`);

      if (businessId) {
        state.businessId = businessId;
      }
      if (tableId) {
        state.tableId = tableId;
      }
      if (tableKey) {
        state.tableKey = tableKey;
      }  
    }
  },
})

export const { initializeSession, setSessionInfo } = customerSessionSlice.actions


export default customerSessionSlice.reducer