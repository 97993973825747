"use client"
import React, { Component } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {actions as modalActions } from '@/redux/slices/modalSlice'
import {
  CONTENT_POSITION,
  IN_SCENE_EFFECT,
  OPACITY_LAYOUT_COLORS,
  OUT_SCENE_EFFECT,
} from "./enums";
import { whichAnimationEvent } from "./utils";

export { OPACITY_LAYOUT_COLORS, CONTENT_POSITION, IN_SCENE_EFFECT, OUT_SCENE_EFFECT };

class Modal extends Component {
  static show() {
    Modal.__singletonRef.__show();
  }
  static hide() {
    Modal.__singletonRef.__hide();
  }
  static setContent(component) {
    Modal.__singletonRef.__setContent(component);
  }
  static setModalProps(props) {
    Modal.__singletonRef.__setModalProps(props);
  }

  constructor(props) {
    super(props);
    this.isClosing = false;
    Modal.__singletonRef = this;
    const {
      opacityLayoutColor,
      contentPosition,
      className,
      inSceneEffect,
      outSceneEffect,
      closeByClickingOutside,
      taggingInfoWhenClickingOutside,
      contentInsideContainer,
      scrollAllowed,
      onCloseHandler,
      closeIdByClickingOutside,
      historyBackOnClose,
    } = props;

    this.state = {
      unMounted: true,
      show: false,
      className,
      inSceneEffect,
      outSceneEffect,
      contentPosition,
      opacityLayoutColor,
      closeIdByClickingOutside,
      closeByClickingOutside,
      taggingInfoWhenClickingOutside,
      contentInsideContainer,
      scrollAllowed,
      onCloseHandler,
      historyBackOnClose,
    };
  }
  
  /* this handle transition and unmounted event */
  componentDidUpdate() {
    const { show, unMounted } = this.state;
    if (!show && !unMounted) {
      const transitionEvent = whichAnimationEvent();
      const element = document.getElementById("animationReference");
      if (transitionEvent) {
        element.addEventListener(transitionEvent, () => {
          // hide modal root
          document.getElementsByClassName("modal-root")[0].classList.remove("visible");
          this.setState({ unMounted: true }, () => {
            this.isClosing = false;
          });
        });
      }
    }
  }

  createContentCss = (contentPosition, inSceneEffect, outSceneEffect, show, className) => {
    const arrayContentPosition = contentPosition && contentPosition.split("--");
    const isCustom =
      Array.isArray(arrayContentPosition) &&
      arrayContentPosition.length > 0 &&
      arrayContentPosition[0] === CONTENT_POSITION.CUSTOM;
    let cssContentPosition = "";
    let customPosition = {};
    let currentContentPosition = contentPosition;
    if (isCustom) {
      cssContentPosition = "Modal_content Modal_content--custom";
      currentContentPosition = "custom";
      if (arrayContentPosition.length > 3) {
        const positionX = arrayContentPosition[1];
        const positionY = arrayContentPosition[2];
        const currentTransform = arrayContentPosition[3];
        customPosition = {
          top: `${positionY}`,
          left: `${positionX}`,
          transform: currentTransform,
        };
      }
    } else {
      cssContentPosition = contentPosition ? `Modal_content Modal_content--${contentPosition}` : "";
    }
    let sceneCss = inSceneEffect
      ? ` Modal_content--${currentContentPosition}--${inSceneEffect}`
      : "";
    if (!show) {
      sceneCss = outSceneEffect
        ? ` Modal_content--${currentContentPosition}--${outSceneEffect}`
        : "";
    }
    const cssOwnClassName = className ? ` ${className}` : "";
    return {
      contentCss: `${cssContentPosition}${sceneCss}${cssOwnClassName}`,
      customPosition,
    };
  };

  createOpacityLayoutCss = (opacityLayoutColor, show) => {
    if (opacityLayoutColor === "blur") {
      return (
        (show && `Single_Modal blur_layout Single_Modal--fadeIn`) ||
        `Single_Modal blur_layout Single_Modal--fadeOut`
      );
    }
    return (
      (show &&
        `Single_Modal opacity_layout opacity_layout--${opacityLayoutColor} Single_Modal--fadeIn`) ||
      `Single_Modal opacity_layout opacity_layout--${opacityLayoutColor} Single_Modal--fadeOut`
    );
  };

  handleCloseOnLayout = () => {
    const { onCloseHandler, closeByClickingOutside } = this.state;
    if (closeByClickingOutside) {
      if (onCloseHandler) {
        onCloseHandler();
      }
    }
  };

  setScrollOnElements = (scrollValue) => {
    const body = document.getElementsByTagName("body")[0];
    const html = document.getElementsByTagName("html")[0];
    body.style.overflowY = scrollValue;
    html.style.overflowY = scrollValue;
  };

  __setContent(content) {
    console.log('modal: => __setContent')
    this.setState({ content });
  }

  __setModalProps(props) {
    console.log('modal: => __setModalProps')
    const propsCopy = { ...props }
    if(propsCopy.show){
      delete propsCopy.show;
    }
    const { scrollAllowed } = props;
    if (scrollAllowed) {
      this.setScrollOnElements("auto");
    }
    this.setState({ ...propsCopy });
  }

  __show = () => {
    console.log('modal: => show')
    const { scrollAllowed } = this.state;
    // show modal-root
    document.getElementsByClassName("modal-root")[0].classList.add("visible");
    // disallow scroll on html,body
    if (!scrollAllowed) {
      this.setScrollOnElements("hidden");
    } else {
      this.setScrollOnElements("auto");
    }
    // set properties
    this.setState({
      show: true,
      unMounted: false,
    });
  };

  __hide = () => {
    this.handleCloseOnLayout();
    this.setScrollOnElements("auto");
    this.setState({ show: false });
  };

  renderModalContent = (content, contentCss, customPosition) => {
    return (
        <div className={`${contentCss}`} style={customPosition}>
          {content}
        </div>
    );
  };

  componentWillUnmount() {
    this.setScrollOnElements("auto");
    this.setState({ show: false });
  }

  render() {
    const { addModal } = this.props;
    const {
      opacityLayoutColor,
      contentPosition,
      className,
      inSceneEffect,
      outSceneEffect,
      unMounted,
      contentInsideContainer,
      closeIdByClickingOutside,
      closeByClickingOutside,
    } = this.state;

    const { show, content } = this.state;
    const cssOpaticyLayout = this.createOpacityLayoutCss(opacityLayoutColor, show);
    const { contentCss, customPosition } = this.createContentCss(
      contentPosition,
      inSceneEffect,
      outSceneEffect,
      show,
      className
    );

    if ((!show && unMounted) || !content) {
      return null;
    }
    return createPortal(
      <>
        {this.renderModalContent(content, contentCss, customPosition)}
        <button
          id="animationReference"
          className={`${cssOpaticyLayout} closeByClickingOutside--${closeByClickingOutside}`}
          tabIndex="-1"
          style={{
            cursor: !closeByClickingOutside ? 'auto':'',
          }}
          onClick={closeByClickingOutside && !this.isClosing ? () => {
            this.isClosing = true;
              addModal({
                id: closeIdByClickingOutside,
                omitAnchorListener: true
              }) } : null}
        />
      </>,
      document.getElementsByClassName("modal-root")[0]
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(null, mapDispatchToProps)(Modal);




Modal.propTypes = {
  /**
    Define la clase CSS custom que se aplicará al componente
   */
  className: PropTypes.string,
  /**
    Establece si la modal puede ser cerrada al hacer click fuera de ella   
   */
  closeByClickingOutside: PropTypes.bool,
  /**
    Establece si la modal puede ser cerrada al hacer click fuera de ella   
   */
  taggingInfoWhenClickingOutside: PropTypes.object,
  /**
    Define la posición que ocupará la modal al ser lanzada   
   */
  contentPosition: PropTypes.oneOf([
    CONTENT_POSITION.CENTER_CENTER,
    CONTENT_POSITION.CENTER_TOP,
    CONTENT_POSITION.RIGHT_TOP,
    CONTENT_POSITION.LEFT_TOP,
    CONTENT_POSITION.CENTER_BOTTOM,
  ]),
  /**
    Define el color de background de la capa layout que cubre la pantalla bajo la modal   
   */
  opacityLayoutColor: PropTypes.oneOf([
    OPACITY_LAYOUT_COLORS.BLACK,
    OPACITY_LAYOUT_COLORS.WHITE,
    OPACITY_LAYOUT_COLORS.DARKBLUE,
    OPACITY_LAYOUT_COLORS.BLACK05,
    OPACITY_LAYOUT_COLORS.TRANSPARENT,
  ]),
  /**
    Define el efecto con el que se mostrará la modal al ser lanzada   
   */
  inSceneEffect: PropTypes.oneOf([
    IN_SCENE_EFFECT.FADE_IN,
    IN_SCENE_EFFECT.FADE_IN_LEFT,
    IN_SCENE_EFFECT.FADE_IN_RIGHT,
    IN_SCENE_EFFECT.FADE_IN_TOP,
    IN_SCENE_EFFECT.FADE_IN_BOTTOM,
  ]),
  /**
    Define el efecto con el que se ocultará la modal al ser cerrada   
   */
  outSceneEffect: PropTypes.oneOf([
    OUT_SCENE_EFFECT.FADE_OUT,
    OUT_SCENE_EFFECT.FADE_OUT_LEFT,
    OUT_SCENE_EFFECT.FADE_OUT_RIGHT,
    OUT_SCENE_EFFECT.FADE_OUT_BOTTOM,
    OUT_SCENE_EFFECT.FADE_OUT_TOP,
  ]),
  /**
    Define si la modal se tiene que mostrar dentro del elemento container de la página o fuera de el   
   */
  contentInsideContainer: PropTypes.bool,
  /**
    Define si la modal permite scroll   
   */
  scrollAllowed: PropTypes.bool,
  /**
    Método que se ejecuta al hacer click fuera del modal  
  */
  onCloseHandler: PropTypes.func,
   /**
    Define si hace historyBack al cerrar la modal
  */
  historyBackOnClose: PropTypes.bool,
};

Modal.defaultProps = {
  className: "",
  closeIdByClickingOutside: '',
  closeByClickingOutside: true,
  taggingInfoWhenClickingOutside: null,
  contentPosition: CONTENT_POSITION.CENTER_BOTTOM,
  opacityLayoutColor: OPACITY_LAYOUT_COLORS.BLACK,
  inSceneEffect: IN_SCENE_EFFECT.FADE_IN,
  outSceneEffect: OUT_SCENE_EFFECT.FADE_OUT,
  contentInsideContainer: false,
  scrollAllowed: false,
  onCloseHandler: null,
  historyBackOnClose: true,
};
