import { createSlice } from '@reduxjs/toolkit'

// Define the initial state using that type
const initialState = {
  floatMenuId: '',
}

export const UI = createSlice({
  name: 'ui',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setFloatMenuId: (state, action) => { state.floatMenuId = action.payload},
    resetFloatMenuId: (state) => { state.floatMenuId = initialState.floatMenuId},
  },
})

export const { setFloatMenuId, resetFloatMenuId } = UI.actions

// Other code such as selectors can use the imported `RootState` type
export const getFloatMenuId = (state) => state.ui.floatMenuId

export default UI.reducer