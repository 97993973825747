import { all, takeLatest } from "redux-saga/effects";
import { initAnchorModalListener, addModal } from "@/sagas/modals-saga";
import { getBusinessByCP, performQuery } from '@/sagas/restaurant-searcher'
import { initProductSearcher, performQuery as performProductQuery } from "@/sagas/product-searcher";
import { initSocket, send2Ticket } from "@/sagas/ticket";
import { launchNotificationFunction, changeNotificationFunction } from '@/sagas/notification-saga'

function* rootSaga() {
  yield all([
    takeLatest("RestaurantSearcher/performQuery", performQuery),
    takeLatest("RestaurantSearcher/getBussinessByCp", getBusinessByCP),
    takeLatest("ProductSearcher/initProductSearcher", initProductSearcher),
    takeLatest("ProductSearcher/performQuery", performProductQuery),
    takeLatest("modals/initAnchorModalListener", initAnchorModalListener),
    takeLatest("ticket/initSocket", initSocket),
    takeLatest("ticket/send2Ticket", send2Ticket),
    takeLatest("notification/launchNotification", launchNotificationFunction),
    takeLatest("notification/changeNotification", changeNotificationFunction),
    takeLatest("modals/addModal", addModal)
  ]);
}

export default rootSaga;
