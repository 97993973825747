import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  modalImport: null,
  modalHistory: [],
  Modal: null,
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    initAnchorModalListener: (state, action) => {},
    addModal: () => {},
    setModalHistory: (state, {payload}) => { state.modalHistory =  payload }
  }
})
export const { initAnchorModalListener, addModal, setModalHistory } = modalSlice.actions
export const actions = modalSlice.actions

export default modalSlice.reducer;